import React, { useEffect, useCallback } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Icon, Image, Segment, Dropdown } from "semantic-ui-react";

import { getCookie } from "app/cookie";

import logo from "app/assets/images/logo.png";
import download_apple from "app/assets/images/download_apple_black.png";
import download_google from "app/assets/images/google-play-badge.png";

import Loading from "app/components/loading";
import { useGlobal } from "app/services/global/context";

const Topbar = () => {
  const navigate = useNavigate();

  const {
    user,
    loaded,
    authenticated,
    onLoaded,
    onGetMe,
    onLogout,
    onAuthenticated,
    onAxiosAuthorization,

    onStripe,

    keys,
    onKeys,
    onFetchPublicKeys,
  } = useGlobal();

  const fetchMe = useCallback(() => {
    const requestAsync = async () => {
      try {
        const token = getCookie("token");

        if (!token) {
          return;
        }

        onAxiosAuthorization(token);

        const response = await onGetMe();

        const { user } = response.data;

        onAuthenticated(user, token);
      } catch (e) {
      } finally {
        onLoaded(true);
      }
    };

    requestAsync();
  }, []);

  const fetchPublicKeys = useCallback(() => {
    const requestAsync = async () => {
      try {
        const response = await onFetchPublicKeys();
        const { keys } = response.data;

        onKeys(keys);
      } catch (e) {
        console.log(e);
      }
    };

    requestAsync();
  }, []);

  const fetchStripe = useCallback(
    (stripe_publishable_key) => {
      const requestAsync = async () => {
        try {
          const stripe = await loadStripe(stripe_publishable_key);

          onStripe(stripe);
        } catch (e) {
          console.log(e);
          console.log("Could not load stripe");
        }
      };

      requestAsync();
    },
    [keys]
  );

  useEffect(() => {
    fetchMe();
    fetchPublicKeys();
  }, []);

  useEffect(() => {
    const { stripe_publishable_key } = keys;

    if (!authenticated || !stripe_publishable_key) {
      return;
    }

    fetchStripe(stripe_publishable_key);
  }, [keys, authenticated]);

  return (
    <Segment
      style={{
        margin: 0,
        padding: "10px 10px",
        border: "none",
        boxShadow: "none",
        background: "transparent",
        marginBottom: 48,
      }}
    >
      <Menu secondary size="huge">
        <Menu.Item style={{ padding: 0, margin: "0px 15px 0px 0px" }}>
          <Link to="/" style={{ display: "flex", alignItems: "center" }}>
            <Image
              src={logo}
              size="mini"
              style={{ margin: 0, borderRadius: 4, width: 40, height: 40 }}
            />
          </Link>
        </Menu.Item>

        {!loaded && (
          <Menu.Menu position="right">
            <Menu.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Loading
                width={100}
                height={100}
                style={{ position: "absolute", right: -36 }}
              />
            </Menu.Item>
          </Menu.Menu>
        )}

        {loaded && !authenticated && (
          <Menu.Menu position="right">
            <Menu.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Link
                to="/signup"
                className="uppercase"
                style={{ color: "#40425a" }}
              >
                Sign up
              </Link>
            </Menu.Item>
            <Menu.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Link
                to="/login"
                className="uppercase"
                style={{ color: "#40425a" }}
              >
                Log in
              </Link>
            </Menu.Item>
          </Menu.Menu>
        )}

        {authenticated && (
          <Menu.Menu position="right">
            <Menu.Item>
              <Dropdown
                trigger={
                  <span>
                    {user.avatar_url ? (
                      <Image
                        src={user.avatar_url}
                        size="large"
                        circular
                        style={{
                          width: 28,
                          height: 28,
                          display: "inline",
                          marginRight: 4,
                          boxShadow: "0px 0px 3px #a9b6bf",
                        }}
                      />
                    ) : (
                      <Icon name="user" style={{ fontSize: "1.2em" }} />
                    )}

                    {user.display_name}
                  </span>
                }
                pointing="top right"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon={{ name: "car", size: "massive" }}
                    value="/trips"
                    text="Trips"
                    onClick={() => navigate("trips")}
                  />

                  <Dropdown.Item
                    icon="log out"
                    color="teal"
                    style={{
                      textAlign: "center",
                    }}
                    text="Sign out"
                    onClick={() => onLogout()}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
    </Segment>
  );
};

export default Topbar;
