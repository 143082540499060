import React, { useState, useEffect, useCallback } from "react";

import Lottie from "react-lottie";
import { useDebounce } from "@uidotdev/usehooks";

import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Grid, Message, Segment } from "semantic-ui-react";

import config from "app/config";

import { toastSuccess } from "app/utils/toast";
import { handleApiError } from "app/utils/errors";
import { useGlobal } from "app/services/global/context";

import SocialLogin from "app/components/social_login";
import FormPhoneInput from "app/components/form-phone-input";

import * as signup from "app/assets/lottie/signup-car.json";

const LoginForm = (props) => {
  const navigate = useNavigate();

  const {
    user,
    onUser,
    identity,
    onIdentity,
    onLogin,
    onValidations,
    onVerifyUsers,
  } = useGlobal();

  const [phone, setPhone] = useState();
  const [disabled, setDisabled] = useState(true);
  const [validLogin, setValidLogin] = useState(false);

  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState({});

  const handleLoginChange = useCallback((login) => {
    let type = "email";

    if (login.match(/^[a-zA-Z]+$/) || login.match(/@/) || login === "") {
      type = "email";

      onIdentity({
        ...identity,
        type,
        login,
      });

      onUser({
        ...user,
        email: login,
      });
    } else {
      type = "phone";

      setPhone(login);

      onIdentity({
        ...identity,
        type,
        login: `+${login}`,
      });

      onUser({
        ...user,
        phone: `+${login}`,
      });
    }
  }, []);

  const handleLoginClick = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        const response = await onVerifyUsers({
          user: { login: identity.login },
        });

        const { exists } = response.data;

        if (exists) {
          await onLogin({
            session: {
              login: identity.login,
            },
          });

          toastSuccess(
            `Verification code has been sent to your provided ${identity.login}.`
          );

          navigate("/verification");
        } else {
          navigate("/signup");
        }
      } catch (e) {
        handleApiError(e, (validations) => {
          setValidations(validations);
        });
      } finally {
        setLoading(false);
      }
    };

    if (validLogin) {
      requestAsync();
    }
  }, [identity, validLogin]);

  const runValidations = useCallback((identity) => {
    const requestAsync = async () => {
      try {
        const { type, login } = identity;

        setValidations({});

        if (type === "phone") {
          await onValidations({
            validations: [
              {
                validate: "format",
                attribute: "phone",
                value: login,
              },
            ],
          });
        } else {
          await onValidations({
            validations: [
              {
                validate: "format",
                attribute: "email",
                value: login,
              },
            ],
          });
        }

        setDisabled(false);
        setValidLogin(true);
      } catch (e) {
        handleApiError(e, (errors) => {
          setValidations(errors);
        });

        setDisabled(true);
      }
    };

    requestAsync();
  }, []);

  useEffect(() => {
    runValidations(identity);
  }, [identity]);

  return (
    <Grid verticalAlign="middle" style={{ padding: 0 }}>
      <Grid.Column style={{ maxWidth: 450, margin: "auto" }}>
        <Lottie
          style={{
            marginBottom: -44,
          }}
          width={240}
          height={240}
          options={{
            loop: false,
            autoplay: true,
            animationData: signup.default,
          }}
          isStopped={false}
          isPaused={false}
        />

        <Form size="large" loading={loading} autoComplete="off">
          <Segment stacked>
            {identity.type === "email" ? (
              <Form.Input
                fluid
                size="large"
                icon="mail"
                autoFocus
                iconPosition="left"
                value={identity.login}
                placeholder="Email / Phone"
                autocomplete="off"
                onChange={(event) => {
                  handleLoginChange(event.target.value);
                }}
                error={
                  validations.login
                    ? {
                        content: validations.login,
                      }
                    : false
                }
              />
            ) : (
              <FormPhoneInput
                focus
                country={"us"}
                value={phone}
                onChange={handleLoginChange}
                hasError={!!validations.login}
              />
            )}

            <Button
              fluid
              // compact
              type="submit"
              color="black"
              size="large"
              disabled={loading || disabled}
              onClick={handleLoginClick}
            >
              Login
            </Button>
          </Segment>
        </Form>
        {false && (
          <SocialLogin
            url={config.url}
            text="Sign in with"
            csrf_token={"csrf_token"}
          />
        )}

        <Message
          style={{
            color: "#474a4e",
            fontWeight: "bold",
            fontSize: "1.1em",
            textAlign: "center",
          }}
        >
          <span style={{ marginRight: 4 }}>New to LUXTI?</span>{" "}
          <Link to="/signup">Sign up</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
