import React, { useState, useEffect, useCallback } from "react";

import Lottie from "react-lottie";
import { useNavigate, Link } from "react-router-dom";
import { Button, Form, Grid, Message, Segment } from "semantic-ui-react";

import * as signupLottie from "app/assets/lottie/signup-car.json";

import { useGlobal } from "app/services/global/context";

import { toastSuccess } from "app/utils/toast";
import { handleApiError } from "app/utils/errors";

import FormPhoneInput from "app/components/form-phone-input";

const SignupForm = () => {
  const navigate = useNavigate();
  const { user, onUser, identity, onIdentity, onLogin, onRegister } =
    useGlobal();

  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState({});

  const handleSignup = useCallback(() => {
    const requestAsync = async () => {
      try {
        setLoading(true);

        await onRegister({
          registration: {
            user,
          },
        });

        await onLogin({
          session: {
            login: identity.login,
          },
        });

        toastSuccess(
          `Verification code has been sent to your provided ${identity.login}.`
        );

        navigate("/verification");
      } catch (e) {
        handleApiError(e, setValidations);
      } finally {
        setLoading(false);
      }
    };

    requestAsync();
  }, [user, identity]);

  const handlePhoneChange = useCallback(
    (phone) => {
      onUser({
        ...user,
        phone: `+${phone}`,
      });

      onIdentity({
        type: "phone",
        login: `+${phone}`,
      });
    },
    [user]
  );

  const handleEmailChange = useCallback(
    (event) => {
      const email = event.target.value;

      onUser({
        ...user,
        email,
      });
    },
    [user]
  );

  useEffect(() => {
    setValidations({});
  }, [user, identity]);

  return (
    <Grid verticalAlign="middle" style={{ padding: 0 }}>
      <Grid.Column style={{ maxWidth: 450, margin: "auto" }}>
        <Lottie
          style={{
            marginBottom: -44,
          }}
          width={240}
          height={240}
          options={{
            loop: false,
            autoplay: true,
            animationData: signupLottie.default,
          }}
          isStopped={false}
          isPaused={false}
        />
        <Form
          size="large"
          style={{
            textAlign: "center",
          }}
          loading={loading}
        >
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              size="large"
              iconPosition="left"
              value={user.full_name}
              placeholder="Full Name"
              onChange={(event) => {
                onUser({
                  ...user,
                  full_name: event.target.value,
                });
              }}
              error={
                validations.full_name
                  ? {
                      content: validations.full_name,
                    }
                  : false
              }
            />
            <Form.Input
              fluid
              icon="mail"
              size="large"
              iconPosition="left"
              value={user.email}
              placeholder="Email"
              onChange={handleEmailChange}
              error={
                validations.email
                  ? {
                      content: validations.email,
                    }
                  : false
              }
            />

            <FormPhoneInput
              value={user.phone}
              country={"us"}
              onChange={handlePhoneChange}
              hasError={!!validations.phone}
            />

            <Form.Checkbox
              checked={user.agree_to_terms}
              onChange={(event) => {
                onUser({
                  ...user,
                  agree_to_terms: !user.agree_to_terms,
                });
              }}
              label={
                <label>
                  I accept the{" "}
                  <a
                    href="/terms"
                    target="_blank"
                    style={{ color: "#094a8b", fontWeight: "bold" }}
                  >
                    Terms of Service
                  </a>
                </label>
              }
              error={validations.agree_to_terms}
            />

            <Button
              fluid
              color="black"
              size="large"
              loading={loading}
              disabled={loading}
              onClick={handleSignup}
            >
              Sign up
            </Button>
          </Segment>
        </Form>

        <Message
          style={{
            color: "#474a4e",
            fontWeight: "bold",
            fontSize: "1.1em",
            textAlign: "center",
          }}
        >
          <span style={{ marginRight: 4 }}>Have an account?</span>{" "}
          <Link to="/login">Login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default SignupForm;
