import React from "react";

import { Header, Container, Grid } from "semantic-ui-react";

const PrivacyPolicy = () => {
  return (
    <Container style={{ margin: 20, fontSize: 18 }}>
      <Header as="h2" icon textAlign="center" style={{ marginBottom: 20 }}>
        <Header.Content
          style={{
            margin: "25px 0px",
            fontSize: "28px",
            color: "#63666b",
          }}
        >
          Privacy Policy
        </Header.Content>
      </Header>
      <Grid>
        <div>
          <h1>Privacy Policy for Luxti</h1>
          <p>
            <strong>Effective Date:</strong> Dec 1st, 2024
          </p>

          <p>
            At <strong>Luxti</strong>, we value your privacy and are committed
            to protecting your personal information. This Privacy Policy
            outlines how we collect, use, and safeguard your data when you use
            our services, including ride services, AI-based trip scheduling, and
            signing up via email or phone.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            When you use Luxti, we may collect the following types of personal
            information:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong>
              <ul>
                <li>
                  <strong>Email Address:</strong> For account creation and
                  communication.
                </li>
                <li>
                  <strong>Phone Number:</strong> For account creation and
                  communication.
                </li>
              </ul>
            </li>
            <li>
              <strong>Ride and Trip Scheduling Information:</strong>
              <ul>
                <li>
                  <strong>Location Data:</strong> For providing accurate ride
                  services and scheduling AI-based trips.
                </li>
                <li>
                  <strong>Trip Details:</strong> Information related to your
                  ride request and scheduling, such as pick-up and drop-off
                  locations, trip duration, and scheduling preferences.
                </li>
              </ul>
            </li>
            <li>
              <strong>Device and Usage Data:</strong>
              <ul>
                <li>
                  We may collect information about your device, including device
                  type, operating system, app usage statistics, and interactions
                  with our services to improve user experience and performance.
                </li>
              </ul>
            </li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>
              <strong>Provide and Improve Services:</strong> To offer ride
              services, AI-based trip scheduling, and enhance your user
              experience.
            </li>
            <li>
              <strong>Account Management:</strong> To create and maintain your
              user account.
            </li>
            <li>
              <strong>Communication:</strong> To send service updates,
              notifications, and customer support communications via email or
              phone.
            </li>
            <li>
              <strong>Analytics:</strong> To analyze how you use our services
              and improve them.
            </li>
            <li>
              <strong>Legal Compliance:</strong> To comply with legal
              requirements and resolve disputes.
            </li>
          </ul>

          <h2>3. Sharing Your Information</h2>
          <p>
            We do not share your personal information with third parties except
            in the following cases:
          </p>
          <ul>
            <li>
              <strong>Service Providers:</strong> We may share your information
              with trusted third-party service providers to facilitate ride
              services, trip scheduling, and other necessary functions.
            </li>
            <li>
              <strong>Legal Requirements:</strong> If required by law, we may
              share your information to comply with legal processes or
              government requests.
            </li>
          </ul>

          <h2>4. Data Security</h2>
          <p>
            We implement industry-standard security measures to protect your
            personal information from unauthorized access, use, or disclosure.
            However, no method of data transmission over the internet or method
            of electronic storage is 100% secure, and we cannot guarantee
            absolute security.
          </p>

          <h2>5. Your Choices</h2>
          <p>You can manage your data and preferences in the following ways:</p>
          <ul>
            <li>
              <strong>Account Information:</strong> You can update or delete
              your account information via the app.
            </li>
            <li>
              <strong>Notifications:</strong> You can opt-out of receiving
              non-essential notifications through the app settings.
            </li>
            <li>
              <strong>Location Data:</strong> You can disable location services
              on your device, but this may affect your ability to use the ride
              service and trip scheduling features.
            </li>
          </ul>

          <h2>6. Children's Privacy</h2>
          <p>
            Our services are not intended for children under the age of 13. We
            do not knowingly collect personal information from children. If we
            become aware that a child under 13 has provided us with personal
            information, we will take steps to delete such information.
          </p>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted within the app and on this page with an updated{" "}
            <strong>Effective Date</strong>. We encourage you to review this
            Privacy Policy periodically to stay informed about how we are
            protecting your data.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>
          <ul>
            <li>
              <strong>Email:</strong>{" "}
              <a href="mailto:support@luxti.com">support@luxti.com</a>
            </li>
            <li>
              <strong>Phone:</strong> 415 2388417
            </li>
            <li>
              <strong>Address:</strong> 4900 Fulton Avenue, San Francisco, CA,
              94121
            </li>
          </ul>
        </div>
      </Grid>
    </Container>
  );
};

export default PrivacyPolicy;
